import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useIntercom } from "react-use-intercom";
import { request } from "../utils/axios-utils";
import SideNav from "./layout/SideNav";
import { useAuth } from "./pages/Login/auth.context";

const Layout: React.FC<{ sideBar?: boolean; logout?: boolean }> = (props) => {
    const [showSideNav, setShowSideNav] = useState<boolean>(true);

    const userJson = sessionStorage.getItem("user");
    const user = JSON.parse(userJson);

    const { setCurrentUser, currentUser } = useAuth();

    const { addToast } = useToasts();

    const Intercom = useIntercom(); // Intercom.boot, Intercom.shutdown, Intercom.hide, Intercom.show, Intercom.update

    useEffect(() => {
        if (user) {
            Intercom.boot({
                userId: user._id,
                name: `${user.profile.infos.first_name} ${user.profile.infos.last_name}`,
                email: user.emails[0].address,
            });
        }
    }, [user]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        if (window.innerWidth > 1024) {
            setShowSideNav(true);
        }
    };

    // deconnection called by button action
    const signOut = async () => {
        setCurrentUser(null);
        sessionStorage.setItem("user", null);
        request({
            addToast,
            url: "/api/logout",
            method: "post",
            data: {},
        })
            .then(() => {
                addToast("Vous êtes déconnecté !", { appearance: "success" });
            })
            .catch();
    };

    return (
        <div>
            {showSideNav && (
                <div
                    onClick={async () => await setShowSideNav(false)}
                    id="overlay-sidenav"
                ></div>
            )}
            <div className="dashboard row">
                {(props.sideBar === undefined || props.sideBar == true) &&
                    showSideNav == true && (
                        <div>
                            <div className="hide-xl">
                                <SideNav
                                    close={async () => {
                                        await setShowSideNav(false);
                                    }}
                                />
                            </div>
                            <div className="hide-xs">
                                <SideNav />
                            </div>
                        </div>
                    )}
                <div className="admin-panel">
                    <div className="toolbar no_print">
                        <div className="group-items-left">
                            <i
                                onClick={() => {
                                    showSideNav == false
                                        ? setShowSideNav(true)
                                        : setShowSideNav(false);
                                }}
                                className="button-icon material-icons show-ipad show-xs"
                            >
                                dehaze
                            </i>
                        </div>

                        <div className="group-items-right">
                            {(props.logout === undefined ||
                                props.logout == true) && (
                                <i
                                    className="button-icon material-icons"
                                    onClick={() => signOut()}
                                >
                                    exit_to_app
                                </i>
                            )}
                        </div>
                    </div>
                    <div className="content">{props.children}</div>
                </div>
                <div className="notificationBar"></div>
            </div>
        </div>
    );
};

export default Layout;
