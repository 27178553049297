import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SideNavItems } from "../../constants/SideBarItems";
import ISideBarItem from "../../interfaces/ISideBarItem";
import { request } from "../../utils/axios-utils";

function DropdownMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleRedirect = (path: any) => {
        // Redirection vers une autre page/produit
        window.location.href = path;
    };

    return (
        <div className="w-full h-16">
            <button
                onClick={toggleDropdown}
                className="h-16 w-full border border-gray-300 shadow-sm px-4 py-2 bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                <div className="flex justify-between">
                    <div className="flex font-bold text-base leading-7">
                        <div className="small-logo"></div>
                        <span className="pl-2 menuTitle">ACCEL GERANCIA</span>
                    </div>
                    {/* <div className="leading-7">{isOpen ? "▲" : "▼"}</div> */}
                </div>{" "}
            </button>

            {/* {isOpen && (
                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="">
                        <button
                            onClick={() => handleRedirect("/nouveau-produit-1")}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                            Accel Gerancia
                        </button>
                        <button
                            onClick={() => handleRedirect("/nouveau-produit-1")}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                            Doc-MJPM
                        </button>
                        <button
                            onClick={() => handleRedirect("/nouveau-produit-2")}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                            Doc-CRG
                        </button>
                    </div>
                </div>
            )} */}
        </div>
    );
}

const SideNav: React.FC<{
    close?: Function;
}> = (props) => {
    const [role, setRole] = useState<string>(null);
    const items = SideNavItems.filter(
        (x: ISideBarItem) => x.roles.indexOf(role) > -1
    );
    const { addToast } = useToasts();

    useEffect(() => {
        if (!role) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setRole(data.roles[0]);
                })
                .catch();
        }
    }, [role]);

    //Return _id from the actual route
    const testRoute = () => {
        var actualRoute = "";
        for (let item of items) {
            if (window.location.href.indexOf(item.href) > -1) {
                actualRoute = item._id;
            }
        }

        // Specific cases for listMajeurs
        if (
            window.location.href.indexOf("single_account") > -1 ||
            window.location.href.indexOf("single_majeur") > -1
        ) {
            actualRoute = "listMajeurs";
        }

        return actualRoute;
    };

    return (
        <nav id="sidenav" className="Sidenav column no_print">
            <div className="layout-start-center row">
                <DropdownMenu />
            </div>
            <div className="menuItemContainer">
                {items.map((item: ISideBarItem) => (
                    <div
                        key={item._id}
                        className={`sidenav_item ${
                            item._id === testRoute() ? "selected" : ""
                        }`}
                    >
                        {props.close && (
                            <Link
                                onClick={() => props.close()}
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        {!props.close && (
                            <Link
                                to={item.href}
                                className="ItemMenu layout-start-center row"
                            >
                                <i className="material-icons">{item.icon}</i>
                                <span className="menuTitle">{item.name}</span>
                            </Link>
                        )}

                        <div className="divider" />
                    </div>
                ))}
            </div>
        </nav>
    );
};
export default SideNav;
